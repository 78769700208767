.tastings-list {
  display: block;

  .tasting {
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 0.4rem 0;

    &.status--active {
      background-color: #333 !important;
      color: #eee;
    }

    &.status--completed {
      opacity: 0.3;
    }

    &:nth-child(odd) {
      background-color: #eee;
    }

    .num {
      flex: 0 0 50px;
      text-align: center;
    }

    .bottles {
      flex: 1 0 200px;
      font-size: 12px;
      line-height: 1.5;

      .taste {
        display: flex;

        .side {
          flex: 0 0 20px; 
          font-weight: 700;
        }

        .bottle {
          flex: 1 0 200px;
        }

        .count {
          flex: 0 0 30px;
        }
      }
    }
  }
}
