.point-system {
  display: flex;

  .point-box {
    flex: 1 1 20%;

    .point-number,
    .point-label {
      font-weight: 700;
      text-align: center;
    }

    .point-number {
      font-size: 42px;
    }

    .point-label {
      color: #999;
      font-size: 14px;
    }

    .point-input {
      font-size: 42px;
      text-align: center;
      width: 100%;
    }
  }
}
