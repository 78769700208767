.create-tasting {
  padding: 2rem 0;

  .ct-side {
    border-bottom: 1px solid #222;
    display: flex;
    padding: 1rem 0;

    &--last {
      border-bottom: none;
    }

    .ct-label {
      align-items: center;
      display: flex;
      padding: 0 0.8rem;
      font-size: 2rem;
      font-weight: 700;
      font-style: italic;
    }

    .ct-options {
      flex: 1 0 auto;

      .ct-options-row {
        align-items: center;
        display: flex;
        padding: 0.2rem 0;

        .ct-auto {
          flex: 1 0;
        }
      }

      label,
      input,
      select {
        flex: 0 0 auto;
        font-size: 14px;
        margin: 0 10px;
      }
    }
  }
}
