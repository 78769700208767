.scoreboard {
  display: block;

  .user {
    align-items: center;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 0.4rem 0;

    &:nth-child(odd) {
      background-color: #eee;
    }

    .action,
    .hits,
    .points,
    .num {
      flex: 0 0 50px;
      font-size: 12px;
      font-weight: 700;
      text-align: center;
    }

    .action {
      cursor: pointer;
      text-decoration: underline;
      text-transform: lowercase;

      &:hover {
        color: #f00;
      }
    }

    .name {
      flex: 1 0 200px;

      span {
        color: #2a9d8f;
        font-size: 12px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
}
